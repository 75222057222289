<template>

  <div class="container">
    <div class="columns is-centered is-multiline">
      <div class="column is-full has-text-centered">
        <h1 class="title is-3">
          Wyszukiwarka firm oferujących usługi samochodowych
        </h1>
      </div>
      <div class="column is-one-quarter">
        <b-field>
          <p class="control">
            <b-input
              rounded
              v-model="service"
              placeholder="Nazwa usługi"
              icon="wrench"
              size="is-medium"
            ></b-input>
          </p>
        </b-field>
      </div>
      <div class="column is-one-fifth">
        <b-field>
          <p class="control">
            <b-select
              rounded
              v-model="type"
              placeholder="Rodzaj usług"
              icon="sort-variant"
              size="is-medium"
            >
              <option value="" selected disabled>Rodzaj usługi</option>
              <option
                v-for="(type, index) in types"
                :key="index"
                :value="type.id"
              >
                {{ type.name }}
              </option>
            </b-select>
          </p>
        </b-field>
      </div>
      <div class="column is-one-fifth">
        <b-field>
          <p class="control">
            <b-input
              rounded
              v-model="start"
              placeholder="Cena min"
              type="number"
              icon="cash"
              size="is-medium"
            ></b-input>
          </p>
        </b-field>
      </div>
      <div class="column is-one-fifth">
        <b-field>
          <p class="control">
            <b-input
              rounded
              v-model="stop"
              placeholder="Cena max"
              type="number"
              icon="cash"
              size="is-medium"
            ></b-input>
          </p>
        </b-field>
      </div>
      <div class="column is-one-fifth">
        <b-field>
          <p class="control">
            <b-input
              rounded
              v-model="city"
              placeholder="Miejscowość"
              icon="city"
              size="is-medium"
            ></b-input>
          </p>
        </b-field>
      </div>
      <div class="column is-one-fifth">
        <b-field>
          <p class="control">
            <b-button
              rounded
              class="is-primary"
              label="Szukaj"
              size="is-medium"
              @click="search"
            ></b-button>
          </p>
        </b-field>
      </div>
    </div>
    <div class="columns is-2" v-if="this.isSearch">
      <p>Użyte filtry:</p>
    </div>
    <div class="columns" v-if="this.isSearch">
      <p>
        <span v-if="service != ''"
          >Nazwa usługi <strong>{{ service }}</strong
          >,
        </span>
        <span v-if="type != ''">
          Rodzaj usługi
          <strong>{{ types.filter((t) => t.id == type)[0].name }}</strong
          >,
        </span>
        <span v-if="city != ''">
          Miasto
          <strong>{{ city }}</strong
          >,
        </span>
        <span v-if="start != ''">
          Cena minimalna <strong>{{ start }} zł</strong>,
        </span>
        <span v-if="stop != ''">
          Cena maksymalna <strong>{{ stop }} zł</strong>
        </span>
      </p>
    </div>
    <div class="columns t-1">
      Liczba znalezionych firm oferujących usługę:
      <strong> {{ companies.length }}</strong>
    </div>
    <div class="columns" v-if="companies">
      <ul>
        <li v-for="(company, index) in companies" :key="index">
          <company-result :company="company"></company-result>
        </li>
      </ul>
    </div>
    <div v-if="isSearch == true && companies.length == 0">
      <div class="box">Brak wyników wyszukiwania</div>
    </div>
    <div class="mt-6 container ">
               <b-button 
          @click="$router.push({name: 'Cars'})"
          type="is-primary" rounded><b-icon  class="mr-2" icon="arrow-left" size="is-small"></b-icon> &nbsp;Powrót</b-button>
    </div>
  </div>
</template>
<script>
import { required, alpha, minLength } from "vuelidate/lib/validators";
import CompanyResult from "@/components/company/CompanyResult";
export default {
  components: {
    "company-result": CompanyResult,
  },
  data() {
    return {
      isSearch: false,
      city: this.$route.params.city || "",
      service: this.$route.params.service || "",
      type: this.$route.params.type || "",
      start: this.$route.params.start || "",
      stop: this.$route.params.stop || "",
    };
  },

  methods: {
    search() {
      this.isSearch = true;
      this.$store.commit("searchService/setCompanies", []);
      let payload = {
        city: this.city,
        service: this.service,
        type: this.type,
        start: this.start,
        stop: this.stop,
      };
      this.$store.dispatch("searchService/searchServices", payload);
    },
  },
  computed: {
    companies: {
      get() {
        ////console.log("companies", this.$store.state.searchService);
        return this.$store.state.searchService.companies;
      },
    },
    types: {
      get() {
        return this.$store.state.searchService.types;
      },
    },
  },
  created() {
    this.$store.dispatch("searchService/servicesType");
  },

  validations: {
    city: {
      required,
      alpha,
      minLength: minLength(0),
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  width: 10vw;
}
</style>