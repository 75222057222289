<template>
  <div class="box mt-2 is-12">
    <div class="columns">
      <div class="column mt-3 is-2">
        <figure class="image is-64x64" v-if="!company.details.logo_url">
          <b-image 
            :src="require('@/assets/img/home/icons/tools.png')"
          ></b-image>

        </figure>
                            <img v-else
                    width=180
            :src="company.details.logo_url"
         />
      </div>
      <div class="column is-one-third">
        <div class="columns">
          <b-icon
            icon="factory"
            size="is-medium"
            type="is-primary"
            class="mt-1 mr-1"
          >
          </b-icon>
          <div class="mt-2 mr-1">{{ company.name }}</div>
        </div>
        <div class="columns">
          <b-icon
            icon="email"
            size="is-medium"
            type="is-primary"
            class="mt-1 mr-1"
          >
          </b-icon>
          <div class="mt-1">{{ company.email }}</div>
        </div>
        <div class="columns">
          <b-icon
            icon="comment-text-outline"
            size="is-medium"
            type="is-primary"
            class="mt-1 mr-1"
          >
          </b-icon>
          <div class="mt-1">{{ company.details.description }}</div>
        </div>
      </div>
      <div class="column is-one-third">
        <div class="columns">
          <div class="columns"></div>
          <b-icon
            icon="map-marker"
            size="is-medium"
            type="is-primary"
            class="mt-1 mr-1"
          >
          </b-icon>

          <div class="columns mt-1 ml-5">
            {{
              "Adres: " +
              company.address[0].local_nb +
              " , ul." +
              company.address[0].street
            }}
            {{ company.address[0].city + ", " + company.address[0].postcode }}
          </div>
        </div>
      </div>
      <div class="column">
        <b-button
          class="is-primary mt-3"
          rounded
          @click="
            $router.push({ name: 'Company', params: { id: company.slug } })
          "
          >Oferta firmy</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyResult",
  props: {
    company: Object,
  },
  created() {
    //console.log(this.company);
  },
};
</script>

<style style="scss" scoped>
.box {
  position: relative;
  top: 1rem;
  min-height: 128px;
  width: 70vw;
}
</style>