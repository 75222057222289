import { render, staticRenderFns } from "./CompanyResult.vue?vue&type=template&id=5eec04b2&scoped=true&"
import script from "./CompanyResult.vue?vue&type=script&lang=js&"
export * from "./CompanyResult.vue?vue&type=script&lang=js&"
import style0 from "./CompanyResult.vue?vue&type=style&index=0&id=5eec04b2&style=scss&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eec04b2",
  null
  
)

export default component.exports